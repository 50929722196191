import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import OpensourceCharts from "../../containers/opensourceCharts/OpensourceCharts";
import Organizations from "../../containers/organizations/Organizations";
import PullRequests from "../../containers/pullRequests/PullRequests";
import Issues from "../../containers/issues/Issues";
import TopButton from "../../components/topButton/TopButton";
import FullStackImg from "../../containers/skills/FullStackImg";
import { Fade } from "react-reveal";
import "./Church.css";
import "../../containers/skills/Skills.css"

class Church extends Component {
  render() {
    return (
      <div className="opensource-main">
        <Header theme={this.props.theme} />
        <div className="skills-main-div">
          <Fade left duration={2000}>
            <h3 className="heading-text" style={{ color: this.props.theme.text }}>
              What does the Bible say?
            </h3>
          </Fade>
          <div className="heading-text-div">
            <Fade right duration={1000}>
              <img
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "50%",
                }}
                src={require(`../../assests/images/bible.png`)}
                theme={this.props.theme}
              />
            </Fade>
          </div>
        </div>


        <div className="skills-main-div">
          <Fade left duration={2000}>
            <div className="skills-image-div">
              <FullStackImg theme={this.props.theme} />
            </div>
          </Fade>
          <div className="heading-text-div">
            <Fade right duration={1000}>
              <h3 className="heading-text" style={{ color: this.props.theme.text }}>
                Church
              </h3>
              <p>
                <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                  Group Alignment and Speaking Engagements
                </h3>
              </p>
              <p>
                <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                  Coaching / Temp Contractor
                </h3>
              </p>
              <span>Operation vs Design • Church Analyst • Design vs Bible • Resolving Conflict</span>
            </Fade>
            <Fade>
              <p>
                <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                  Boundaries - Church, Marriage, Groups
                </h3>
              </p>
              <span>Christian Life Coach • Conflict Resolution</span>
            </Fade>
          </div>
        </div>

        {/* <Organizations theme={this.props.theme} />
        <OpensourceCharts theme={this.props.theme} />
        <PullRequests theme={this.props.theme} /> 
        <Issues theme={this.props.theme} /> */}
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Church;
