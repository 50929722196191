import React, { Component } from "react";
import { Router, Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Splash from "../pages/splash/Splash";
import Business from "../pages/business/BusinessComponent";
import Personal from "../pages/personal/Personal";
import Church from "../pages/church/Church";
import Contact from "../pages/contact/ContactComponent";
import Projects from "../pages/projects/Projects";
import { settings } from "../portfolio.js";
import Error404 from "../pages/errors/error404/Error";

export default class Main extends Component {
  render() {
    if (settings.isSplash) {
      return (
        <div>
          <BrowserRouter>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => (
                  <Splash {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/home"
                render={(props) => <Home {...props} theme={this.props.theme} />}
              />
              <Route
                path="/personal"
                exact
                render={(props) => (
                  <Personal {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/business"
                render={(props) => (
                  <Business {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/church"
                render={(props) => (
                  <Church {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/contact"
                render={(props) => (
                  <Contact {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/splash"
                render={(props) => (
                  <Splash {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/projects"
                render={(props) => (
                  <Projects {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="*"
                render={(props) => (
                  <Error404 {...props} theme={this.props.theme} />
                )}
              />
            </Switch>
          </BrowserRouter>
        </div>
      );
    } else {
      return (
        <div>
          <BrowserRouter basename="/">
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <Home {...props} theme={this.props.theme} />}
              />
              <Route
                path="/home"
                render={(props) => <Home {...props} theme={this.props.theme} />}
              />
              <Route
                path="/personal"
                exact
                render={(props) => (
                  <Personal {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/business"
                render={(props) => (
                  <Business {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/church"
                render={(props) => (
                  <Church {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/contact"
                render={(props) => (
                  <Contact {...props} theme={this.props.theme} />
                )}
              />
              <Route
                path="/projects"
                render={(props) => (
                  <Projects {...props} theme={this.props.theme} />
                )}
              />
            </Switch>
          </BrowserRouter>
        </div>
      );
    }
  }
}
