import React from "react";
import "./Skills.css";
import SkillSection from "./SkillSection";
import { Fade } from "react-reveal";

export default function Skills(props) {
  const theme = props.theme;
  return (
    <div className="main" id="skills">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            What we Do?
          </h1>
        </Fade>
      </div>
      <SkillSection theme={theme} />
      <div className="skills-header">
        OUR BACKGROUND
      </div>
      <div style={{ textAlign: 'center' }}>
        <u>Graduate Level Education</u><br />
        Management<br />
        Human Resources Development<br />
        Health Care Administration<br />
        Christian Counseling<br />

        <br />
        <u>Undergraduate Education</u><br />
        Business Administration<br />
        Health: Biology<br />

        <br />
        <u>Additional Areas</u><br />
        Education<br />
        Veteran Army Officer- Veteran Benefits<br />
        Manufacturing<br />
        Logistics<br />
        HOA’s<br />
        Strategic Operations
      </div>
    </div>
  );
}
