import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Businesses from "../../containers/business/Business";
import Certifications from "../../containers/certifications/Certifications";
import CompetitiveSites from "../../components/competitiveSites/CompetitiveSites";
import BusinessImg from "./BusinessImg";
import { competitiveSites } from "../../portfolio";
import { certifications } from "../../portfolio";
import "./BusinessComponent.css";
import { Fade } from "react-reveal";

import DataScienceImg from "../../containers/skills/DataScienceImg";

class Business extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="education-main">
        <Header theme={this.props.theme} />
        <div className="basic-education">
          <Fade bottom duration={2000} distance="40px">
            <div className="heading-div">
              <div className="heading-img-div">
                {/* <BusinessImg theme={theme} /> */}
                <Fade left duration={2000}>
                  <div className="skills-image-div">
                    <DataScienceImg theme={theme} />
                  </div>
                </Fade>
              </div>
              <div className="heading-text-div">
                <h1 className="heading-text" style={{ color: theme.text }}>
                  Business
                </h1>
                <p>
                  <h3 className="heading-sub-text" style={{ color: theme.text }}>
                    Group Alignment and Speaking Engagements
                  </h3>
                </p>
                <p>
                  <h3 className="heading-sub-text" style={{ color: theme.text }}>
                    Coaching / Temp Contractor
                  </h3>
                </p>
                <span>HRD/Admin/Customer Service • Management • Healthcare • Logistics • "Risk" Management • Safety • Quality Assurance • Change Management • Conflict Resolution</span>
                <h3 className="heading-sub-text" style={{ color: theme.text }}>
                  Business Assessments/Strategy
                </h3>
                <span>Profit Loss • Gap Analyses • Growth and Development</span>
              </div>
            </div>
          </Fade>
          <Businesses theme={this.props.theme} />
          {certifications.certifications.length > 0 ? (
            <Certifications theme={this.props.theme} />
          ) : null}
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Business;
