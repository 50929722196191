import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import "./Personal.css";
import { personal } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ExperienceImg from "./PersonalImg";


export default class Personal extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-main">
        <Header theme={theme} />
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <h1 style={{ textAlign: 'center' }}>Personal</h1>
            <h2 style={{ textAlign: 'center', color: this.props.theme.text }}>
              Life Coach
            </h2>
            <div className="skills-main-div">
              <Fade left duration={2000}>
                <div className="skills-image-div">
                  <ExperienceImg theme={this.props.theme} />
                </div>
              </Fade>
              <div className="heading-text-div">
                <Fade right duration={1000}>
                  <h3 className="heading-text" style={{ color: this.props.theme.text }}>
                  </h3>
                </Fade>
                <Fade>
                  <p>
                    <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                      Conflict Resolution
                    </h3>
                  </p>
                  <span>Marriage • Family/Friends • Boundary Setting</span>
                </Fade>
                <Fade>
                  <p>
                    <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                      Health Plans
                    </h3>
                  </p>
                  <span>Health/Fitness</span>
                </Fade>
                <Fade>
                  <p>
                    <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                      Complex or Basic Documents
                    </h3>
                  </p>
                  <span>Formal Letters • Faxes • Forms</span>
                </Fade>
                <Fade>
                  <p>
                    <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                      Healing Skin Care
                    </h3>
                  </p>
                  <span>Dry,Scaly, Blemished Skin Care • Dry, Scaly Scalp Care</span>
                </Fade>
                <Fade>
                  <p>
                    <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                      Veterans Benefits
                    </h3>
                  </p>
                </Fade>
                <Fade>
                  <p>
                    <h3 className="heading-sub-text" style={{ color: this.props.theme.text }}>
                      HOA's
                    </h3>
                  </p>
                </Fade>
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}
